import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import { post } from "@rails/request.js";
import { Sourdough, toast } from "sourdough-toast";

class Upload {
  constructor(file) {
    this.directUpload = new DirectUpload(
      file,
      "/rails/active_storage/direct_uploads",
      this
    );
    this.progress = 0;
    this.tempId = `temp_${Date.now()}_${Math.random()
      .toString(36)
      .substr(2, 9)}`;
    this.fileUploadElement = null;
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) =>
      this.updateProgress(event)
    );
  }

  updateProgress(event) {
    this.progress = (event.loaded / event.total) * 100;
    if (this.fileUploadElement) {
      const progressBar = this.fileUploadElement.querySelector(
        "[data-progress-bar]"
      );
      if (progressBar) {
        progressBar.style.width = `${this.progress}%`;

        if (this.progress === 100) {
          setTimeout(() => {
            progressBar.style.width = "0%";
          }, 2000);
        }
      } else {
        console.warn("Progress bar element not found");
      }
    } else {
      console.debug("File upload element not found");
    }
  }

  process() {
    this.fileUploadElement = this.insertUpload();

    this.directUpload.create((error, blob) => {
      if (error) {
        console.error("Error uploading file", error);
      } else {
        this.updateUploadElement(blob.signed_id);

        const fileData = {
          signed_blob_id: blob.signed_id,
          file: {
            size: blob.byte_size,
            filename: blob.filename,
            mime_type: blob.content_type,
          },
        };

        post("/files", {
          body: fileData,
          contentType: "application/json",
          responseKind: "turbo-stream",
        })
          .then((response) => {
            console.log("File uploaded", response);
          })
          .catch((error) => {
            console.error("Error saving file", error);
          });
      }
    });
  }

  numberToHumanSize(bytes, decimals = 1) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  formatDateTime(date) {
    const options = {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    return date.toLocaleString("en-US", options).replace(",", "");
  }

  insertUpload() {
    const template = document.querySelector("template");
    const fileUpload = template.content.cloneNode(true).firstElementChild;

    fileUpload.id = `upload_${this.tempId}`;

    fileUpload.querySelector("[data-filename]").textContent =
      this.directUpload.file.name;
    fileUpload.querySelector("[data-size]").textContent =
      this.numberToHumanSize(this.directUpload.file.size);
    fileUpload.querySelector("[data-created-at]").textContent =
      this.formatDateTime(new Date());

    const progressBar = fileUpload.querySelector("[data-progress-bar]");
    progressBar.style.width = "0%";

    const uploadList = document.querySelector("#uploads");
    uploadList.prepend(fileUpload);

    return fileUpload;
  }

  updateUploadElement(signedBlobId) {
    const fileUpload = document.getElementById(`upload_${this.tempId}`);
    console.log(fileUpload);
    if (fileUpload) {
      fileUpload.id = `upload_${signedBlobId}`;
    }
  }
}

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.element.addEventListener("dragover", this.preventDefaults, false);
    this.element.addEventListener("dragenter", this.preventDefaults, false);
  }

  disconnect() {
    this.element.removeEventListener("dragover", this.preventDefaults, false);
    this.element.removeEventListener("dragenter", this.preventDefaults, false);
  }

  trigger() {
    this.inputTarget.click();
  }

  dragOver(e) {
    e.preventDefault();
  }

  acceptFiles(e) {
    e.preventDefault();
    const files = e.target.files || e.dataTransfer.files;

    [...files].forEach((file) => {
      new Upload(file).process();
    });
  }

  preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log("preventDefaults");
  }
}
