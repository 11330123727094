import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { text: String };
  static targets = ["defaultIcon", "checkIcon", "defaultText", "copiedText"];

  copy() {
    navigator.clipboard.writeText(this.element.dataset.clipboardText).then(
      () => {
        this.showCopiedState();
        setTimeout(() => this.resetState(), 1000);
      },
      () => {
        this.showFlash("Failed to copy link", "error");
      }
    );
  }

  showCopiedState() {
    this.defaultIconTarget.classList.add("hidden");
    this.checkIconTarget.classList.remove("hidden");
    this.defaultTextTarget.classList.add("hidden");
    this.copiedTextTarget.classList.remove("hidden");
    this.element.classList.remove("bg-gray-200", "hover:bg-gray-300");
    this.element.classList.add("bg-green-200", "hover:bg-green-300");
  }

  resetState() {
    this.defaultIconTarget.classList.remove("hidden");
    this.checkIconTarget.classList.add("hidden");
    this.defaultTextTarget.classList.remove("hidden");
    this.copiedTextTarget.classList.add("hidden");
    this.element.classList.add("bg-gray-200", "hover:bg-gray-300");
    this.element.classList.remove("bg-green-200", "hover:bg-green-300");
  }

  showFlash(message, type = "success") {
    const event = new CustomEvent("flash", { detail: { message, type } });
    window.dispatchEvent(event);
  }
}
