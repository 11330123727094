import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["previous", "next"];

  connect() {
    this.handleKeydown = this.handleKeydown.bind(this);
    document.addEventListener("keydown", this.handleKeydown);
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeydown);
  }

  handleKeydown(event) {
    if (event.key === "ArrowLeft" && this.hasPreviousTarget) {
      window.location.href = this.previousTarget.href;
    } else if (event.key === "ArrowRight" && this.hasNextTarget) {
      window.location.href = this.nextTarget.href;
    }
  }
}
